@import 'common';
@import 'blocks/main-form';
@import 'blocks/nuestras-promociones';
@import 'blocks/somos-experiencia';
@import 'blocks/testimonios';
@import 'blocks/whatsapp';
@import 'partials/form-modal';

.home-header {
	position: relative;
	display: block;
	width: 100%;
	height: 55vw;
	max-height: 650px;
	margin: 0 auto;
	padding: 0;
	box-sizing: border-box;
	.header-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		padding: 0;
		box-sizing: border-box;
		.home-slider {
			position: relative;
			width: 100%;
			height: 100%;
			margin: 0 auto;
			padding: 0;
			box-sizing: border-box;
			.slick-list,
			.slick-track {
				height: 100%;
			}
			.slick-slide {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				margin: 0 auto;
				padding: 0;
				box-sizing: border-box;
				.text {
					position: relative;
					display: flex;
					flex-flow: column nowrap;
					justify-content: center;
					align-items: flex-start;
					max-width: $max-w;
					width: 100%;
					height: 100%;
					margin: 0 auto;
					padding: 0 50px;
					box-sizing: border-box;
					z-index: 2;
					.title {
						margin: 0;
						font-family: $font;
						font-weight: 500;
						font-size: 80px;
						line-height: 85px;
						color: #FFFFFF;
						strong {
							font-weight: bold;
						}
					}
					.description {
						margin: 0 0 50px;
						font-family: $font;
						font-size: 40px;
						line-height: 45px;
						color: #FFFFFF;
					}
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					margin: 0;
					padding: 0;
					object-fit: cover;
				}
			}
		}
	}
	.header-form {
		position: absolute;
		left: 50%;
		bottom: 90px;
		display: block;
		max-width: 670px;
		width: 100%;
		margin: 0 auto;
		padding: 15px 30px;
		box-sizing: border-box;
		background: rgba(#FFFFFF, .9);
		transform: translateX(-50%);
		border-radius: 6px;
		z-index: 3;
		.form-title {
			margin: 0 auto;
			font-family: $font;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			color: $main-color;
			strong {
				font-weight: 600;
			}
		}
		.one-row-form {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			width: 100%;
			margin: 0 auto;
			padding: 0;
			box-sizing: border-box;
			.input-group {
				position: relative;
				display: block;
				margin: 0 0 0 20px;
				padding: 0;
				box-sizing: border-box;
				&:first-of-type {
					margin: 0;
				}
				&.select {
					&:after {
						position: absolute;
						top: 50%;
						right: 15px;
						display: block;
						font-family: $font-icons;
						font-size: 9px;
						line-height: 9px;
						transform: translateY(-50%) rotate(180deg);
						color: $text-color;
						z-index: 2;
						content: '\e915';
					}
				}
				select {
					-webkit-appearance: none;
					appearance: none;
					display: flex;
					flex-flow: row nowrap;
					justify-content: flex-start;
					align-items: center;
					min-height: 40px;
					min-width: 190px;
					padding: 10px 25px;
					box-sizing: border-box;
					font-family: $font;
					font-weight: normal;
					font-size: 14px;
					line-height: 14px;
					color: $text-color;
					text-align: center;
					background: #FFFFFF;
					border: 1px solid $text-color;
					border-radius: 6px;
					outline: none;
					cursor: pointer;
					&::selection {
						background: $main-color;
					}
					option {
						text-align: left;
						color: $text-color;
						&:disabled {
							font-weight: bold;
						}
						&:hover {
							background: $main-color;
						}
					}
				}
			}
			.btn-wrapper {
				margin: 0 0 0 30px;
				.btn {
					display: flex;
					flex-flow: row nowrap;
					justify-content: center;
					align-items: center;
					max-height: 50px;
					max-width: 160px;
					width: 100%;
					padding: 15px 30px;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					span.icon-search {
						font-size: 17px;
						margin: 0 20px 0 0;
					}
				}
			}
		}
	}
	
}

// Tablet
@include querie($tablet) {
	.home-header {
		height: 700px;
		.header-image {
			.home-slider {
				.slick-slide {
					.text {
						justify-content: flex-start;
						padding: 120px 50px;
						.title {
							font-size: 60px;
							line-height: 70px;
						}
						.description {
							margin: 0;
							font-size: 30px;
							line-height: 35px;
						}
					}
				}
			}
		}
		.header-form {
			width: 500px;
			bottom: 130px;
			.one-row-form {
				display: block;
				.input-group {
					margin: 15px auto 0;
					&:first-of-type {
						margin: 15px auto 0;
					}
					select {
						width: 100%;
					}
				}
				.btn-wrapper {
					margin: 25px auto 0;
					.btn {
						max-width: 100%;
					}
				}
			}
		}
	}
}

// Mobile
@include querie($mobile) {
	.home-header {
		height: 630px;
		.header-image {
			.home-slider {
				.slick-slide {
					.text {
						padding: 75px 18px 105px;
						.title {
							font-size: 40px;
							line-height: 40px;
						}
						.description {
							font-size: 20px;
							line-height: 25px;
						}
					}
				}
			}
		}
		.header-form {
			max-width: 500px;
			width: calc(100% - 36px);
			margin: 0;
			padding: 35px 18px;
			bottom: 130px;
		}
	}
}